import api from '~api'
import type { AxiosResponse } from 'axios'
import { AddressRecord, PostSubscriptionRequest } from '~api/APITypes'
import { applySnapshot, flow, getParent, types } from 'mobx-state-tree'
import { CookiesService, Logger, SentryCaptureException } from '~utils'
import type {
  IStripeCouponStatus,
  SnStripeBillingAddress,
} from './StripeModels'
import {
  IStripeCoupon,
  IStripePrice,
  StripeBillingAddress,
  StripeCouponStatus,
  StripeCustomer,
  StripeInvoice,
  StripePrice,
  StripeSubscription,
} from './StripeModels'
import { IRootStore, rootStore } from '~stores/root'

const logger = Logger('StripeStore', {
  nsBackground: '#008cdd',
  nsColor: 'black',
})

export const StripeStore = types
  .model('StripeStore', {
    hydrated: false,
    couponId: '',
    couponCodeStatus: types.optional(StripeCouponStatus, 'initial'),
    elementsClientSecret: types.maybe(types.string),
    customer: types.maybeNull(StripeCustomer),
    subscription: types.maybeNull(StripeSubscription),
    billingAddress: types.maybeNull(StripeBillingAddress),
    pricesData: types.optional(types.array(StripePrice), []),
    invoices: types.optional(types.array(StripeInvoice), []),
  })
  .views((self) => ({
    /**
     * Here we remove coupons to the prices if the user is not eligible.
     */
    get prices(): Array<IStripePrice> {
      if (!self.pricesData) return []

      const root = getParent<IRootStore>(self)
      if (root.userStore.eligibleForPromotion) return self.pricesData

      return self.pricesData.map((price) => {
        price.removeCoupon()
        return price
      })
    },
    get discountedAmount() {
      if (!self.subscription?.discount || !self.subscription?.plan?.amount)
        return null

      if (self.subscription.discount.coupon.amount_off) {
        return (
          self.subscription.plan.amount -
          self.subscription.discount.coupon.amount_off
        )
      }

      if (self.subscription.discount.coupon.percent_off) {
        return (
          self.subscription.plan.amount *
          (1 - self.subscription.discount.coupon.percent_off / 100)
        )
      }

      return self.subscription.plan.amount
    },
  }))
  .actions((self) => ({
    setCouponCodeStatus: (status: IStripeCouponStatus) => {
      self.couponCodeStatus = status
    },
    setCouponId: (couponId: string) => {
      self.couponId = couponId
      CookiesService.setCoupon(couponId)
    },
    removeCouponId: () => {
      logger.log('removeCouponId')
      self.couponId = ''
      CookiesService.removeCoupon()
    },
    setElementsClientSecret: (clientSecret) => {
      self.elementsClientSecret = clientSecret
    },
    getPricingByInterval(interval: string) {
      return self.prices.find((price) => {
        return price.recurring.interval === interval
      })
    },
    setBillingAddress(data: SnStripeBillingAddress) {
      if (!self.billingAddress)
        self.billingAddress = StripeBillingAddress.create(data)
      else applySnapshot(self.billingAddress, data)
    },
    fetchPrices: flow(function* (couponId?: string) {
      if (!couponId) return yield api.stripe.controllersStripeSearchPrice()
      return yield api.stripe.controllersStripeSearchPrice({
        coupon_id: couponId,
      })
    }),
    applyPrices: (prices: Array<IStripePrice>) => {
      const orderedPrices = prices.sort((a, b) => {
        if (a.recurring.interval === 'year') return -1
        else return 0
      })
      applySnapshot(self.pricesData, orderedPrices)
    },
  }))
  .actions((self) => ({
    setupIntentPost: flow(function* () {
      try {
        const response = yield api.stripe
          .controllersStripePostSetupIntent({})
          .then((res) => res.json())
        logger.log('[setupIntentPost/response]', response)
        if (response.client_secret) {
          const _clientSecret = response.client_secret
          rootStore.stripeStore.setElementsClientSecret(_clientSecret)
          return response.client_secret
        }
      } catch (e) {
        logger.log('[setupIntentPost/error]', e)
      }
    }),
    applyCoupon: flow(function* (couponId?: string) {
      const response = yield self.fetchPrices(couponId)
      self.applyPrices(response.data.prices || [])
      logger.log('[applyCoupon]', response)

      if (response.status === 200) {
        self.setCouponId(couponId)
        self.setCouponCodeStatus('success')
      } else {
        self.removeCouponId()
        self.setCouponCodeStatus('initial')
      }
    }),
    getPrices: flow(function* () {
      const response = yield self.fetchPrices()
      self.applyPrices(response.data.prices || [])
      logger.log('[getPrices]', response)
    }),
    getSubscriptionById: flow(function* (subscriptionId: string) {
      const res = yield api.stripe
        .controllersStripeGetSubscription(subscriptionId)
        .then((res) => res.json())
      logger.log('[getSubscriptionById]', res)
      if (!res.subscription) return
      self.subscription = StripeSubscription.create(res.subscription)
    }),
    deleteSubscription: flow(function* (subscriptionId: string) {
      return yield api.stripe.controllersStripeDeleteSubscription(
        subscriptionId,
      )
    }),
    putBillingAddress: flow(function* (data: AddressRecord) {
      const response: AxiosResponse<AddressRecord> =
        yield api.stripe.controllersStripePutAddress(data)
      logger.log('[putBillingAddress]', response)

      if (response.data)
        self.billingAddress = StripeBillingAddress.create(response.data)
      return response
    }),
    getPriceById: (priceId) => {
      return self.prices.find((price) => price.id === priceId)
    },
    getSubscriptionDetail: flow(function* () {
      const response = yield api.stripe
        .controllersStripeSearchSubscription()
        .then((res) => res.json())
      logger.log('[getSubscriptionDetail/search]', response)

      if (
        response.subscriptions?.data &&
        response.subscriptions?.data.length > 0
      ) {
        /**
         * We are selecting the first element from the array, this is hacky,
         * probably we need to protect the store and backend from creating or having
         * more than one.
         */
        const subscription = response.subscriptions.data[0]
        self.subscription = StripeSubscription.create(subscription)
      }
    }),
    getBillingAddress: flow(function* () {
      try {
        const response: AxiosResponse<AddressRecord> =
          yield api.stripe.controllersStripeGetAddress()
        logger.log('[getBillingAddress]', response)
        if (
          response.data.line1 &&
          response.data.line2 &&
          response.data.country &&
          response.data.city &&
          response.data.postal_code
        )
          self.setBillingAddress(response.data)
      } catch (e) {
        logger.error('[getBillingAddress/error]', e)
      }
    }),
    getCustomer: flow(function* () {
      try {
        const response = yield api.stripe
          .controllersStripeGetCustomer()
          .then((res) => res.json())
        logger.log('[getCustomer]', response)
        if (response.customer)
          self.customer = StripeCustomer.create(response.customer)
      } catch (e) {
        logger.error("Couldn't get customer", e)
        SentryCaptureException('getCustomer/error', e)
      }
    }),
    patchCustomer: flow(function* (paymentMethodId: string) {
      const response = yield api.stripe
        .controllersStripePatchCustomer({
          default_payment_method_id: paymentMethodId,
        })
        .then((res) => res.json())
      logger.log('[patchCustomer]', response)

      if (response.customer) {
        self.customer = StripeCustomer.create(response.customer)
      }

      return response
    }),
    getInvoices: flow(function* () {
      try {
        const res = yield api.stripe
          .controllersStripeSearchInvoice()
          .then((res) => res.json())
        logger.log('[getInvoices]', res)

        if (res.invoices.data) {
          // Filter out invoices with null invoice_pdf
          const validInvoices = res.invoices.data.filter(
            (invoice) => invoice.invoice_pdf !== null,
          )

          // Transform and create StripeInvoice instances
          const transformedInvoices = validInvoices.map((invoice) => ({
            account_country: invoice.account_country,
            total: invoice.total,
            status: invoice.status,
            created: invoice.created,
            invoice_pdf: invoice.invoice_pdf,
            // Add other fields as necessary
          }))

          // Replace the store's invoices with the new filtered and transformed invoices
          self.invoices.replace(
            transformedInvoices.map((invoice) => StripeInvoice.create(invoice)),
          )

          return self.invoices
        }
        return []
      } catch (e) {
        logger.error('[getInvoices/error]', e)
        SentryCaptureException(e)
        return []
      }
    }),
  }))
  .actions((self) => ({
    deletePaymentMethod: flow(function* (paymentMethodId: string) {
      const response = yield api.stripe.controllersStripeDeletePaymentMethod(
        paymentMethodId,
      )

      // Update the customer.
      yield self.getCustomer()

      logger.log('[deletePaymentMethod]', response)
      return response
    }),
    setSubscription: flow(function* (price_id: string, coupon_id?: string) {
      const response = yield api.stripe.controllersStripePostSubscription({
        price_id,
        coupon_id,
      })
      logger.log('[setSubscription/response]', response)

      if (response.data?.subscription_id) {
        yield self.getSubscriptionById(response.data?.subscription_id)
      } else {
        logger.log("[setSubscription]: Couldn't set subscription")
      }
    }),
    setSubscriptionById: flow(function* (props: PostSubscriptionRequest) {
      const response = yield api.stripe.controllersStripePostSubscription(props)
      if (response.data?.subscription_id) {
        yield self.getSubscriptionById(response.data?.subscription_id)
        logger.log('[setSubscription]', response)
      }
    }),
    hydratePrices: flow(function* () {
      // const isPartnerAccount = self.subscription?.discount.coupon.duration === "forever";
      // const couponId = isPartnerAccount ? self.subscription.discount.coupon.id : CookiesService.getCoupon();
      // logger.log("hydratePrices/coupon: ", couponId);
      // if (couponId) yield self.applyCoupon(couponId);
      // else yield self.getPrices();

      yield self.getPrices()
    }),
  }))
  .views((self) => ({
    /**
     * Returns the first coupon that finds.
     */
    get anyCoupon(): IStripeCoupon | undefined {
      if (self.prices.length < 1) return undefined

      const price = self.prices.find((price) => price.coupon)
      if (price) return price.coupon

      return undefined
    },
    get hasInvoices() {
      return self.invoices && self.invoices.length >= 1
    },
    get lastInvoice() {
      if (self.invoices.length >= 1)
        return self.invoices[self.invoices.length - 1]

      return null
    },
  }))
  .actions((self) => ({
    hydrate: flow(function* () {
      self.setCouponCodeStatus('initial')
      yield self.getBillingAddress()
      yield self.getSubscriptionDetail()
      yield self.hydratePrices()
      yield self.getCustomer()
      yield self.getInvoices()
      self.hydrated = true
    }),
    logOut: () => {
      applySnapshot(self, {
        hydrated: false,
        elementsClientSecret: undefined,
        customer: null,
        subscription: null,
        billingAddress: null,
        invoices: [],
      })
    },
  }))
